<template>
    <div>
        <Navbar page="Notificações" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="flex justify-end">
              <button v-if="total > 0" @click="deletarVisualizada" type="button" class="mb-2 text-red-800 py-2 hover:bg-red-800 hover:text-white border-2 border-red-800 rounded  text-center text-sm w-64 font-semibold">
                <span class="inline-block">Excluir Visualizadas</span>
              </button>
            </div>
            <div v-for="item in list" :key="item._id" :to="item.caminho" @click="open(item)" class="w-full border border-gray-100 shadow-lg rounded py-4 px-3 md:px-5 my-5 cursor-pointer relative" :class="{'bg-blue-100': !item.lida }">
                <h3 class="text-gray-700 font-r font-medium text-xl mb-2"> {{ item.titulo }}</h3>
                <p class="text-gray-600 font-r font-medium text-sm mb-2"> {{ item.descricao }}</p>
                <p class="text-gray-600 font-r font-medium text-sm mb-2"> {{ item.data | moment("DD/MM/YYYY HH:mm") }}</p>
                <div class="absolute rounded py-2 px-2 bg-blue-400 -top-4 right-2 md:right-3">
                    <p class="text-white font-r text-xs md:text-sm_2"> {{ item.tipo }}</p>
                </div>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      route: 'notificacoes',
      list: [],
      page: 1,
      perPage: 10,
      total: 0,
      query: { page: 1, limit: 10, skip: 0  },
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { skip: this.query.skip, limit: this.query.limit  });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async open(notificacao) {
        await this.$http.put(`/v1/${this.route}/`, { _id: notificacao._id, lida: true });
        const reqnotificacoes = await this.$http.post(`/v1/notificacoes/countNotificacoes`);
        await this.$store.commit('setNotificacoes', reqnotificacoes.data); 
        if(notificacao.caminho) this.$router.push({ path: notificacao.caminho });
    },

    async deletarVisualizada () {
      await this.$http.post(`/v1/${this.route}/deletarVisualizadas`);
      this.start();
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      this.$router.push({ path: `/${this.route}`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async mounted() {
    this.sockets.subscribe(this.$store.state.user._id.toString(), async function(d) {
      this.list.unshift(d.data);
    });
  },
  beforeDestroy() {
    this.sockets.unsubscribe(this.$store.state.user._id.toString());
  },
}
</script>